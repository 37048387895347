<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-tag-text</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Perks ({{ totalPerks }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'perk.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Perk
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="perk-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-0"
            v-for="item in tabItems"
            :key="item.value"
            :ref="item.value"
            :disabled="defaultStatus !== item.value && loading"
            @click="handleTabClick(item.value)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search title or building"
          @input="search"
        ></v-text-field>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="perks"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalPerks"
        @click:row="editItem"
        @update:options="fetch"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.rating="{ item }">
          <PerkRating :perk="item" :small="true" />
        </template>
        <template v-slot:item.is_active="{ item }">
          <v-checkbox v-model="item.is_active" disabled readonly hide-details />
        </template>
        <template v-slot:item.building="{ item }">
          <div class="text-truncate" style="max-width: 200px">
            {{ item.buildings.map((building) => building.name).join(', ') }}
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(perks.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Perk list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import PerkRating from '@/views/Home/Perks/components/PerkRating'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'PerksPage',
  mixins: [FilterTableMixin, ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    PerkRating,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'header',
      sortOrder: true,
      tabItems: [
        { tab: 'Active ', value: 'active', index: 1 },
        { tab: 'Inactive', value: 'inactive', index: 0 },
        { tab: 'All', value: null, index: 2 },
      ],
      headers: [
        {
          text: 'Header',
          value: 'header',
          width: '20%',
        },
        {
          text: 'Building',
          value: 'building',
          width: '10%',
        },
        {
          text: 'Rating',
          value: 'rating',
          sortable: false,
          width: '13%',
        },
        {
          text: 'Active',
          value: 'is_active',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '15%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      selected: [],
      defaultStatus: 'active',
    }
  },

  created() {
    this.clearPerks()
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      perks: (state) => state.perk.list,
      listMeta: (state) => state.perk.listMeta,
      permissions: (state) => state.auth.permissions,
      totalPerks: (state) => state.perk.totalPerks,
      paramsPerk: (state) => state.perk.queryParams,
      scrollPosition: (state) => state.perk.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.PERKS_CREATE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getPerks: 'perk/getPerks',
    }),

    ...mapMutations({
      clearPerks: 'perk/clearPerkList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'perk/setQueryParams',
      setScrollPosition: 'perk/setScrollPosition',
    }),

    handleTabClick(status) {
      this.defaultStatus = status
      this.defaultFilter = 'header'
      this.activeAction()
    },

    fetchInitParams() {
      if (this.paramsPerk?.filter) {
        const isActive = this.paramsPerk.filter
        this.defaultStatus = isActive
      } else {
        this.defaultStatus = null
      }

      if (this.paramsPerk?.sort) {
        if (this.paramsPerk.sort[0].includes('-')) {
          this.defaultFilter = this.paramsPerk.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsPerk?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'header'
      }

      if (this.paramsPerk?.search) {
        this.filter.search = this.paramsPerk.search
      }

      this.fetch(null, 1, this.paramsPerk)
    },

    filterList(filterValue) {
      this.clearPerks()
      this.fetch(null, filterValue)
    },

    activeAction() {
      this.clearPerks()
      this.fetch()
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    getTabNameByIndex(index) {
      switch (index) {
        case 0:
          return 'active'
        case 1:
          return 'inactive'
        default:
          return null
      }
    },

    search: debounce(function () {
      this.clearPerks()
      this.fetch()
    }, 600),

    async fetch(options, page = 1, paramsPerk = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalPerks
            : options.itemsPerPage
          : 25,
        filter:
          typeof this.defaultStatus === 'number'
            ? this.getTabNameByIndex(this.defaultStatus)
            : this.defaultStatus,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : 'header',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getPerks(paramsPerk || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
      this.setParams(params)
    },
    editItem(item) {
      this.$router.push({
        name: 'perk.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return

      const scrollPosition = this.scrollPosition
      this.setScrollPosition(0)

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
